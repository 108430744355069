import React from 'react'
import { useNavigate} from "react-router-dom"
import { useAuth,  } from '../../contexts/AuthContext'
import './NavBar.css'
import { UserOutlined , HomeOutlined  } from '@ant-design/icons';

function NavBar() {
    const {page,currentUser, usernane, screenSize } = useAuth()
    const navigate = useNavigate()

  return (
    <nav className='container' style={{display: screenSize.width > 513 && 'none'}}>
    <div  style={{color: page === 'home' ?'#333': '#767676', textDecoration:'none' }}>
      <div className='icon-container'>
              <HomeOutlined className='icon' onClick={() => navigate(`/`)}/>
              <div style={{fontSize:12}}>Home</div>
      </div>
      </div>


    <div className='user-icon-container' style={{color: page === 'user' ?'#333': '#767676', textDecoration:'none' }} >
      
      {currentUser? 
      <div className='icon-container'>
             <img alt='/' onClick={() => navigate(`/user/${usernane(currentUser.email)}`)}  style={{width:27, height:27,cursor:'pointer', borderRadius:100, border: page === 'user' && '2px solid #333', marginBottom:page === 'user' ?0: 0 }} src={currentUser.photoURL}/>
             <div style={{fontSize:12, marginTop:0}}>Profile</div>
      </div>
      : 
      <div className='icon-container'>
         <UserOutlined  onClick={() => navigate(`/login`)}   className='icon'/>
      <div style={{fontSize:12}}>Login</div>
</div> }
      
      </div>
    </nav>
  )
}

export default NavBar