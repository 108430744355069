// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import {GoogleAuthProvider, getAuth} from 'firebase/auth';
import {getStorage} from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

    apiKey: "AIzaSyCe3qtYxhaGPZwzREOIlRCr8H2odZLOxhw",
  
    authDomain: "yetiapp-92e2f.firebaseapp.com",
  
    projectId: "yetiapp-92e2f",
  
    storageBucket: "yetiapp-92e2f.appspot.com",
  
    messagingSenderId: "454840320566",
  
    appId: "1:454840320566:web:54b2d7c557cee9ea924e97",
  
    measurementId: "G-TBM8ZGC1CQ"
  
  };
  


// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const provider = new  GoogleAuthProvider();

export const db  = getFirestore(app);

export const storage  = getStorage(app);




