import React, { useEffect } from 'react'
import { useAuth,  } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import '../App.css';
import {FireFilled, InfoCircleOutlined, PlayCircleFilled ,SafetyCertificateFilled , MobileFilled, InteractionFilled} from '@ant-design/icons';


function Home() {
  const navigate = useNavigate()
    const {getChallenges,screenSize, listChallenges, pages ,currentUser } = useAuth()
    
useEffect(() => { 
    getChallenges()
       // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => { 
   screenSize.width > 513 && navigate('/desktop')
    pages('home')
       // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [screenSize]);


           //// const remove = (list, item) => {
            ////   let index = list.indexOf(item); 
            ///    if (index > -1) { 
            ///      return  list.splice(index+1, 1);}
          ///  }

  return (
    <div className='home-container'>

        <div className='home-logo'>
            <FireFilled />
           <p>Yeti</p>
        </div>

        <div className='home-title'>
        Welcome to the first challenge platform
        </div>



           
          {!listChallenges &&  <div>


<div className='home-challenge' >

</div>

<div className='home-button-container'>
<button className='home-button'>See the challenge</button>
</div>         </div>}



       
         {listChallenges?.map((challenge, index) => {
         return(
            <div key={index}>
            {challenge.active && 
            <div>
            

             <div className='home-challenge' style={{backgroundImage:`url(${challenge.picture})`}}>
            <div className='filter'>
              <div style={{backgroundColor:'white',color:'#333',fontSize:12, width:36,borderRadius:3, display:'flex', padding:2 , fontWeight:'normal' }}>
               <p style={{textAlign:'center', width:'100%'}}>New</p>
              </div>
               <p style={{fontWeight:'normal', marginTop:97}}>Proposed by</p> 
               <p>{challenge.creator.challenger}</p>
               <p>{challenge.creator.job} <a rel="noreferrer"  target="_blank"style={{color:'white'}}  href={challenge.about}><InfoCircleOutlined /></a></p>
            </div>
         </div>
         
          <div className='home-button-container'>
            <button onClick={() => currentUser ? navigate(`/challenge/${challenge.id}`): navigate('/login')} className='home-button'>See the challenge</button>
         </div>
         </div>

            }
            </div>
         )
         })}


        

        


         <div className='week-title' style={{marginTop:55, marginBottom:20}}>
          How it works
         </div>


<div style={{marginBottom:30}}>
    
         <div style={{ marginTop:25, display:'flex', alignContent:'center', alignItems:'center'}} >
            <div style={{marginRight:10, fontSize:20}}>
            <PlayCircleFilled />  
                                  </div>
        <div>

            <p className='notice-text'>Choose a challenge and watch the creator's video.</p>

        </div>

         </div>

        


         <div style={{ marginTop:25, display:'flex', alignContent:'center', alignItems:'center'}} >
            <div style={{marginRight:10, fontSize:20}}>
            <MobileFilled />    
                                </div>
        <div>
            <p className='notice-text'>Reproduce the challenge on video.</p>

        </div>

         </div>



         <div style={{ marginTop:25, display:'flex', alignContent:'center', alignItems:'center'}} >
            <div style={{marginRight:10, fontSize:20}}>
            <InteractionFilled />
                        </div>
        <div>
            <p className='notice-text'>Click on <span style={{fontStyle:'italic'}}>participate</span> and send your video.</p>

        </div>

         </div>

         <div style={{ marginTop:25, display:'flex', alignContent:'center', alignItems:'center'}} >
            <div style={{marginRight:10, fontSize:20}}>
            <SafetyCertificateFilled />        
                </div>
        <div>
                     <p className='notice-text'>If the challenge creator validates your video, it will appear below his.</p>

        </div>

  

         </div>     
</div>



    </div>
  )
}

export default Home



///useEffect(() => { 
    ////getChallenges()
 ///}, [listChallenges]);


//////{currentUser && <button onClick={() =>
 //////  postChallenges( listChallenges.length + 1, {challenger:"Bron James", job:"Basketter pro"}, [],{name:currentUser.displayName, uid:currentUser.uid}, false, 'url', 'picture', 'about')}>{isLoading ? "chargement" :"Add challenge"}</button>}
   /////   {newChallengeM && <div>{newChallengeM}</div>}
 /////