import React, { useEffect } from 'react'
import {FireFilled} from '@ant-design/icons';
import { useAuth,  } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

function CGU() {
    const {screenSize, pages} = useAuth()
    const navigate = useNavigate()

    useEffect(() => { 
        screenSize.width > 513 && navigate('/desktop')
         pages('user')
   // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [screenSize]);

  return (
    <div style={{padding:20, paddingBottom:110}}>

<div className='login-logo' style={{marginTop:45, marginBottom:65, justifyContent:'center'}}>
            <FireFilled />
           <p>Yeti</p>
        </div>


         <p style={{textAlign:"center", fontWeight:'bold'}}>TERMS OF SERVICE</p>
         
         <br></br>
         <br></br>

         <p>
           
         ARTICLE 1. LEGAL INFORMATION<br></br>
            Under Article 6 of Law No. 2004-575 of June 21, 2004 on confidence in the digital economy, this article specifies the identity of the various stakeholders in the context of its implementation and monitoring.
            “The platform” represents the Yeti website accessible at this address https://yeti-app.com.<br></br>
            The Yeti platform is edited by Randy Lele Talla
            Email address: randy@yeti-app.com.<br></br>
           
            The publication director of the site is: Randy Lele Talla.<br></br>
           
            The Yeti platform is hosted by:
            1&1 IONOS, whose head office is located at the following address:
            7 Pl. de la Gare, 57200 Sarreguemines<br></br>
            Telephone number: 0970808911
           
            ARTICLE 2. PRESENTATION OF THE SITE<br></br>
            The Yeti platform aims to:
            Challenge platform.<br></br>
            <br></br>
           
            ARTICLE 3. CONTACT<br></br>
            For any question or request for information concerning the site, or any report of illegal content or activities, the user can contact the publisher at the following e-mail address: contact@yeti-app.com
            <br></br>
            <br></br>
            ARTICLE 4. ACCEPTANCE OF THE TERMS OF USE<br></br>
            Access to and use of the site are subject to acceptance and compliance with these General Terms and Conditions of Use.<br></br>
            The publisher reserves the right to modify, at any time and without notice, the site and services as well as these T&Cs, in particular to adapt to changes to the site by providing new functionalities or deleting or modification of existing functionalities.
            It is therefore advisable for the user to refer before any navigation to the latest version of the T&Cs, accessible at any time on the site. In case of disagreement with the T&Cs, no use of the site can be made by the user.
            <br></br>
            <br></br>
            ARTICLE 5. ACCESS AND NAVIGATION<br></br>
            The publisher implements the technical solutions at its disposal to allow access to the site 24 hours a day, 7 days a week. It may nevertheless at any time suspend, limit or interrupt access to the site or to certain pages of this one in order to carry out updates, modifications of its content or any other action deemed necessary for the proper functioning of the site.
            These T&Cs apply, as necessary, to any variation or extension of the site on existing or future social and/or community networks.
            <br></br>
            <br></br>
            ARTICLE 6. SITE MANAGEMENT<br></br>
            For the proper management of the site, the publisher may at any time:
            <br></br>• Suspend, interrupt or limit access to all or part of the site, reserve access to the site, or to certain parts of the site, to a specific category of Internet user;
            <br></br> • Delete any information that could disrupt its operation or contravene national or international laws, or the rules of Netiquette;
            <br></br>• Suspend the site in order to carry out updates.
            <br></br>
            <br></br>
            ARTICLE 7. SERVICES RESERVED FOR REGISTERED USERS<br></br>
           
            1. REGISTRATION<br></br>
            Access to certain services is subject to user registration.<br></br>
            Registration and access to the services of the platform are reserved exclusively for capable natural persons, who have completed and validated the registration form available online on Yeti, as well as these General Conditions of Use.
            When registering, the user undertakes to provide accurate, sincere and up-to-date information on his person and his marital status. The user must also regularly check the data concerning him in order to maintain its accuracy.
            The user must therefore imperatively provide a valid e-mail address, on which the platform will send him a confirmation of his registration for his services. An e-mail address cannot be used more than once to register for the services.
            Any communication made by Yeti and its partners is therefore deemed to have been received and read by the user. The latter therefore undertakes to regularly consult the messages received on this e-mail address and to respond within a reasonable time if necessary.
            Only one registration for the services of the site is allowed per natural person.<br></br>
            Yeti reserves in any case the possibility of refusing a request for registration to the services in the event of non-compliance by the user with the provisions of these General Conditions of Use.
            <br></br>
            <br></br>
           
            2. UNSUBSCRIBE<br></br>
            Regularly registered users may request unsubscription at any time by going to the dedicated page in their Personal Space.
            <br></br>
            <br></br>
           
            3. DELETION OF PERSONAL SPACE AT THE INITIATIVE OF THE SITE EDITOR<br></br>
            It is brought to the user's attention that the publisher reserves the right to delete the personal space of any User who contravenes these conditions of use and sale, and more particularly in the following cases:
            <br></br>• If the user makes illicit use of the platform;
            <br></br>• If the user, when creating his personal space, voluntarily transmits erroneous information to the platform.<br></br>
            In the event that the publisher decides to delete the user's personal space for one of these reasons, this cannot constitute damage for the user whose account has been deleted.
            This deletion does not constitute a waiver of legal proceedings that the publisher could undertake against the user who has contravened these rules.
            <br></br>
            <br></br>
            ARTICLE 8. RESPONSIBILITIES<br></br>
            The editor is only responsible for the content that he has edited himself.
            The publisher is not responsible:
            <br></br>• In the event of technical or computer problems or failures or compatibility of the site with any hardware or software;
            <br></br>• Direct or indirect, material or immaterial, foreseeable or unforeseeable damage resulting from the use or difficulties in using the site or its services;
            <br></br>• Intrinsic characteristics of the Internet, in particular those relating to the lack of reliability and lack of security of the information circulating on it;
            <br></br>• Illegal content or activities using his site without his having duly taken cognizance thereof within the meaning of Law No. 2004-575 of June 21, 2004 on confidence in the digital economy and Law No. 2004-801 of August 6, 2004 on the protection of individuals with regard to the processing of personal data.
            Furthermore, the platform cannot guarantee the accuracy, completeness and timeliness of the information disseminated there.
            The user is responsible:
            <br></br>• Protection of its equipment and data;
            <br></br>• The use he makes of the site or its services;
            <br></br>• If he respects neither the letter nor the spirit of these T&Cs.
           
            <br></br>
            <br></br>ARTICLE 9. HYPERTEXT LINKS<br></br>
            The platform may contain hypertext links pointing to other websites over which Yeti has no control. Despite the prior and regular checks carried out by the publisher, the latter declines all responsibility for the content that can be found on these sites.
            Finally, Yeti reserves the right to have a hypertext link pointing to its site deleted at any time, if the site deems it not to comply with its editorial policy.
           
            <br></br>
            <br></br>ARTICLE 10: CONFIDENTIALITY<br></br>
            In addition to these General Conditions, the platform has a privacy policy which describes how personal data is processed when the user visits the site, as well as how cookies are used.
            By browsing the platform, the user declares to have also read the aforementioned privacy policy.
           
            <br></br>
            <br></br>ARTICLE 11. INTELLECTUAL PROPERTY<br></br>
            The structure of the platform but also the texts, graphics, images, photographs, sounds, videos and computer applications that compose it are the property of the publisher and are protected as such by the laws in force under intellectual property.
            Any representation, reproduction, adaptation or partial or total exploitation of the content, registered trademarks and services offered by the site, by any process whatsoever, without the prior, express and written authorization of the publisher, is strictly prohibited and would be liable to constitute an infringement within the meaning of articles L. 335-2 and following of the Intellectual Property Code. And this, with the exception of elements expressly designated as free of rights on the platform.
            Access to the platform does not constitute recognition of a right and, in general, does not confer any intellectual property rights relating to an element of the platform, which remain the exclusive property of the publisher.<br></br>
            <br></br>
            The user is prohibited from entering data on the platform that would modify or could modify its content or appearance.
           
            <br></br>
            <br></br>ARTICLE 12. APPLICABLE LAW AND JURISDICTION<br></br>
            These General Conditions of Use are governed by French law. In the event of a dispute and in the absence of an amicable agreement, the dispute will be brought before the French courts in accordance with the rules of jurisdiction in force.
           
                      </p>


    </div>
  )
}

export default CGU