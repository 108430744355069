import React, { useEffect } from 'react'
import { useAuth,  } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import '../App.css';
import {FireFilled, LoadingOutlined} from '@ant-design/icons';

function Login() {
  const {signup,screenSize,isLoading, pages} = useAuth()
  const navigate = useNavigate()


    useEffect(() => { 
      screenSize.width > 513 && navigate('/desktop')
       pages('user')
   // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenSize]);




  return (
    <div className='home-container' style={{paddingRight: 30, paddingLeft: 30, display:'flex' ,
     justifyContent:'center'}}>


{isLoading ? <div className='loading'><LoadingOutlined  spin /></div> :

<div>
<div className='login-logo'>
            <FireFilled />
           <p>Yeti</p>
        </div>

        <div className='login-title'>
            Join our community of challengers now
        </div>
 <button className='login-button' onClick={signup}>
            <img alt='/'  style={{width:20 , height:20, borderRadius:55}} src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2008px-Google_%22G%22_Logo.svg.png'></img>

    <p>Sign in with Google</p> 
        <div style={{width:20 , height:20, borderRadius:55}} ></div>

    </button>
     
    <p style={{marginTop:50, fontSize:12,position:'fixed',left:0, right:0, bottom:100, textAlign:'center', color:'#333'}}>By continuing, you agree to Yeti's <span onClick={() => navigate(`/cgu`)}style={{color:'#FC6D3F'}}>Terms of Services.</span></p> 

    </div> }

    </div>
  )
}

export default Login