import './App.css';
import React from 'react'
import {BrowserRouter as Router, Routes, Route, Navigate, Outlet} from "react-router-dom"
import { AuthProvider, useAuth } from './contexts/AuthContext';
import User from './pages/User';
import Home from './pages/Home';
import Display from './pages/Display';
import Login from './pages/Login';
import CGU from './pages/CGU';
import Desktop from './pages/Desktop';
import NavBar from './components/Navbar/NavBar';


function App() {

  
    

  return (
    <AuthProvider>
      <div style={{ minHeight: "100vh", width: '100%' }}>
        <Router>

          <Routes>
            <Route path="/desktop" element={<Desktop />} />
            <Route path="/login" element={<Login />}   />
            <Route exact path="/" element={<Home />} />
            <Route path="*" element={<Home />} />
            <Route path="/cgu" element={<CGU />} />



            <Route exact path="/login" element={<PublicRoute/>}>
            <Route path="/login" element={<Login />}   />
            </Route>

            <Route exact path="/user/:username" element={<PrivateRoute/>}>
             <Route path="/user/:username" element={<User />} />
            </Route>

            <Route path="/challenge/:id/" element={<PrivateRoute/>}>
            <Route path="/challenge/:id/" element={<Display />}  />
            </Route>



          </Routes>
          <NavBar />
        </Router>

      </div>
    </AuthProvider>

  );
}

export default App;





const PrivateRoute = () => {
const {currentUser} = useAuth()
  return currentUser ? <Outlet /> : <Navigate to="/" />;
}

const PublicRoute = () => {
  const {currentUser} = useAuth()
    return !currentUser ? <Outlet /> : <Navigate to="/" />;
  }