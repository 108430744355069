import React, { useEffect, useState } from 'react'
import { useAuth,  } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import '../App.css';
import { MoreOutlined,InfoCircleOutlined, HolderOutlined, TeamOutlined} from '@ant-design/icons';


function User() {
  const {signout,     screenSize,
    currentUser, usernane, pages ,getChallenges, listChallenges } = useAuth()
  const navigate = useNavigate()
  const [signOut, setSignOut] = useState(false)
  const [contact, setContact] = useState(false)
  const [selected, setSelected] = useState(false)
  const MyChallenges = listChallenges?.filter(function(challenge) { return challenge.user.uid === currentUser.uid });

  const tryNumber = []
  const succeedNumber= []

  const [tn, setTn] = useState([])
  const [sn, setSn] = useState([])

    

  useEffect(() => { 
   screenSize.width > 513 && navigate('/desktop')
    pages('user')
       // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [screenSize]);


  useEffect(() => { 
    getChallenges()
       // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);



 


   
  useEffect(() => { 
    setTn(tryNumber)
    setSn(succeedNumber)
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listChallenges?.[0]]);





  
  return (
    <div>

      <div className='signout-container' >
    { signOut ? <> 
     {!contact && <> <p className='signout-text' onClick={() => navigate(`/cgu`) }>ToS</p>
    <p className='signout-text' onClick={() => setContact(true)}>Contact</p>
    <p className='signout-text' onClick={signout}>Logout</p></>}

  { contact && <> <a rel="noreferrer" style={{color:'#333'}}  href='mailto:contact@yeti-app.com' className='signout-text' target="_blank" >E-mail</a>
  <a rel="noreferrer" target="_blank" style={{color:'#333'}}  href='https://wa.me/message/ZXHFCNOSU4QAI1' className='signout-text' >WhatsApp</a></>}

        <HolderOutlined onClick={() => {setSignOut(false); setContact(false)}} className='signout-icon'/></> :
        <MoreOutlined onClick={() =>setSignOut(true)} className='signout-icon' />}

      </div>

      
 {currentUser && <>
       <div  className='img-container'>
        <div className='user-filter'>
       <img alt='/' className='user-img' src={currentUser.photoURL}/>
        </div>
       </div>

       <div className='user-name'>
        <p style={{textAlign:'center'}}>{currentUser.displayName}</p>
       </div>

       <div className='user-username'>
        <p style={{textAlign:'center'}}>@{usernane(currentUser.email)}</p>
       </div>

       <div className='user-data-container'>
        <p style={{color:'transparent'}}>invisible</p>
        <p>{tn.length} {tn.length > 1 ? 'Tries' :'Try'}</p>
        <p>{sn.length} {sn.length > 1 ? 'Successes' :'Success' }</p>
        <p style={{color:'transparent'}}>invisible</p>

       </div>

      

      <div className='user-title' style={{justifyContent:'space-evenly'}}>
        <p onClick={() => setSelected(false)}  style={{ borderBottom:!selected ? '3px solid': '3px solid transparent', paddingBottom:7 , fontWeight:'normal'}}>Successful challenges</p>
        <p onClick={() => setSelected(true)}  style={{borderBottom:selected ? '3px solid': '3px solid transparent', fontWeight:'normal'}} >My challenges</p>
       </div>
      
       {MyChallenges.length < 1 && selected && <div className='user-empty'>
        <p>Coming soon</p>
       </div>}

       {sn.length < 1 && !selected && <div className='user-empty'>
        <p>None at the moment</p>
       </div>}

       <div className='user-succeed-container'>
  
     { listChallenges?.map((challenge, index) =>{
      return(
        <div style={{display: selected && 'none' }} key={index}>
          {  challenge.videos.map((video, index) =>{
 return(
    <div key={index}>
        {  video.creatorUid === currentUser.uid && tryNumber.push(video) && video.validate && succeedNumber.push(video) && 
       <div  onClick={() => navigate(`/challenge/${challenge.id}`)} className='user-succeed' style={{backgroundImage:`url(${challenge.picture})`}} >
       <div className='user-succeed-filter'> 
         <div>
          <p>Proposed by</p>
          <p style={{fontWeight:'bold'}}>{challenge.creator.challenger}</p>
               <p style={{fontWeight:'bold'}}>{challenge.creator.job} <a rel="noreferrer" target="_blank"style={{color:'white'}}  href={challenge.about}><InfoCircleOutlined /></a></p>
               </div>
          </div>
          </div>}
    </div>
  )
})}
        </div>
      )
  })}


{  listChallenges?.map((challenge, index) =>{
          
 return(
    <div style={{display: !selected && 'none' }} key={index}>
        {  challenge.user.uid === currentUser.uid && 
       <div  onClick={() => navigate(`/challenge/${challenge.id}`)} className='user-succeed' style={{backgroundImage:`url(${challenge.picture})`}} >
       <div className='user-succeed-filter'> 
         <div>
          <p style={{fontWeight:'bold'}}>{challenge.creator.challenger}</p>
               <p style={{fontWeight:'bold'}}>{challenge.creator.job} <a rel="noreferrer" target="_blank"style={{color:'white'}}  href={challenge.about}><InfoCircleOutlined /></a></p>
               {challenge.user.uid === currentUser.uid && <p style={{fontWeight:'bold'}}>{challenge.participants.length} <TeamOutlined /></p>}
               </div>
          </div>
          </div>}
    </div>
  )
      
  })}



       </div>
  </>}
      
      
    </div>
  )
}

export default User



///{currentUser && <button onClick={() => postChallenges(23,currentUser.uid, currentUser.displayName, [])}>{isLoading ? "chargement" :"Add challenge"}</button>}
////{newChallengeM && <div>{newChallengeM}</div>}

/*
{  listChallenges?.[0].videos.map((video) =>{
 return(
    <>
        {  video.creatorUid === currentUser.uid && tryNumber.push(video) && video.validate && succeedNumber.push(video) && 
        <div>
          yoo
          </div>}

    </>
  )
})}


 <div className='user-title'>
        <p>Successful challenges</p>
       </div>


*/

