import React, { useEffect, useState } from 'react'
import { useAuth,  } from '../contexts/AuthContext'
import { useParams } from "react-router-dom"
import '../App.css';
import { InfoCircleOutlined ,HeartOutlined ,HeartFilled,TrophyFilled ,DeleteOutlined, LoadingOutlined ,HourglassFilled, MobileFilled} from '@ant-design/icons';
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid';

function Display() {
    const {     likeVideo ,  screenSize, newVideoM    ,deleteVideo, pages ,currentUser,
         getChallenge, currentChallenge, isLoading, addVideo, newVideo, videoUrl, name   ,participation,getChallenge2,   usernane, videoLoading} = useAuth()
    const {id} = useParams();
    const [videoUpload, setVideoUpload] = useState()
     const [succeed, setSucceed] = useState()
     const liste = []
     const navigate = useNavigate()
     const [open, setOpen] = useState(false)
     const [isValidate, setIsValidate] = useState(false)
  



   
 useEffect(() => { 
      screenSize.width > 513 && navigate('/desktop')
       pages('home')
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenSize]);


    useEffect(() => { 
      getChallenge(id)
      /// console.log(currentChallenge?.videos)
             // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    useEffect(() => { 
      liste?.map((video) => {
        currentUser?.uid === video.creatorUid && setIsValidate(true)
        return video
      })
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liste]);


      useEffect(() => { 
       !isLoading && setSucceed(Math.round(liste.length *100 / currentChallenge?.videos.length) )
              // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [liste]);


      

  useEffect(() => {
   if(videoUrl){  
  newVideo(id,  add(
     {creator:currentUser.displayName, creatorUid:currentUser?.uid, creatorUserName:usernane(currentUser?.email), profilePic:currentUser?.photoURL, url:videoUrl ,likes:[], validate:false, uid:v4(), active:true}))
      participation(id, participate(currentUser?.uid) ) 
     setVideoUpload(null)
     getChallenge2(id)} 
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUrl]);

  


     const remove = (uid )=> {
 getChallenge2(id)
 var filtered = currentChallenge.videos.filter(function(video) { return video.uid !== uid; });
 return filtered
     }



     const add = ( oneVideo) => {
      getChallenge2(id)
      currentChallenge.videos.splice(0,0,oneVideo)
       return currentChallenge.videos
     }


     const like = (index, uid) => {
      getChallenge2(id)

    if (currentChallenge.videos[index].likes.includes(uid) )  {
      currentChallenge.videos[index].likes =   currentChallenge.videos[index].likes.filter(e => e !== uid)
      return currentChallenge.videos
    }
    currentChallenge.videos[index].likes.push(uid)

     return currentChallenge.videos
     }


     const participate = (uid) => {
    if (currentChallenge?.participants.includes(uid) )  {
      return currentChallenge?.participants
    }
         currentChallenge?.participants.push(uid)

     return currentChallenge?.participants
     
     }






  return (
    <div style={{paddingBottom: 80}}>  
  

  <div className='video-header' style={{backgroundColor: isLoading ? '#333' : 'white'}}>
     <ReactPlayer className='video-header' config={{ file: { attributes: { playsInline: true, }, }, }} style={{backgroundColor: isLoading ? '#333' : 'white'}}  loop url={currentChallenge?.url} controls   width='100%' height='100%'/>
         </div>   
         {isLoading ? <div className='loading'><LoadingOutlined  spin /></div> : 

<>

        <div className='display-text'>
           <p>{currentChallenge?.creator.challenger}</p>
               <p style={{color:'#767676', fontWeight:'normal'}}>{currentChallenge?.creator.job} <a rel="noreferrer"   target="_blank" href={currentChallenge?.about}>{!isLoading  && <InfoCircleOutlined />}</a></p>
                
              
        </div> 
               

        <div onClick={() => {setOpen(!open); setVideoUpload()}} className='display-button'>
          <p>Participate</p>
         <p > {succeed > 0 ? succeed :0}% <TrophyFilled/></p>
          </div>  
          
        { open && !isValidate && <div className='display-participate-container'>
        
   { newVideoM === '' ? <>
    <input name="file" id="file" className="inputfile" style={{backgroundColor:'#FC6D3F', color:'white', borderColor:'white', marginTop:0, alignContent:'center', display:'flex'}}  
    type='file'  accept=".mov,.mp4"  onChange={(event) => {setVideoUpload(event.target.files[0])}}></input>
<label htmlFor="file">{videoUpload? 'Your video has been selected' :'Choose a video' }</label>
   {videoUpload && <button onClick={() => addVideo(videoUpload)} className='login-button' style={{backgroundColor:'#FC6D3F', color:'white', borderColor:'white', marginTop:15}} >
          <div style={{width:20 , height:20, borderRadius:55}} ></div>

   { videoLoading && videoUpload ? <LoadingOutlined  spin /> : <p>Send</p> }
        <div style={{width:20 , height:20, borderRadius:55}} ></div>

    </button>}

    <p style={{color:'white', fontWeight:'normal', marginTop:20}}><span><HourglassFilled/></span> Video of 30 sec max</p>
    <p style={{color:'white', fontWeight:'normal', marginTop:10}}><span><MobileFilled/></span> Vertical format {!succeed ? '(like TikTok)' : ''}</p>

      </> : 
      <p style={{fontWeight:'normal'}}>{newVideoM}</p>
      }

          </div>}


         {isValidate && open && <div className='display-participate-container'>
           <p style={{fontWeight:'normal'}}>Your video has already been validated, you can no longer participate in this challenge.</p>
          </div>}


       <p className='display-title'>{currentChallenge && `Validated by ${currentChallenge?.creator.challenger}`}</p>
       {  !succeed && <div className='user-empty'>
        <p>None at the moment</p>
       </div>}

        <div className='display-container'>
        
          {currentChallenge?.videos.map((video, index) =>{
        
            return(
              <div key={index}>
          { video.validate && liste.push(video) && <div key={index}   style={{marginTop:30}}>
          
          <div className='video-data-user'>
            <div className='video-image'>
            <img alt='/' style={{width:'100%', height:'auto', borderRadius:100}}src={video.profilePic}></img>
            
            </div>
            <p>{name(video.creator)}</p>
          </div>
          <div className='video'>
          <ReactPlayer className='video' loop url={video.url} playsInline controls  width='100%' height='100%'/> 
         </div>
         
          
          <div className='video-like'>  
             <div style={{display:'flex', alignContent:'center', alignItems:'center'}}>
            
           {currentChallenge.videos[index].likes.includes(currentUser.uid) ?
            <HeartFilled onClick={() => likeVideo(id, like(index, currentUser.uid))} style={{ color:'#FC6D3F', marginRight:5 }}/> :
            <HeartOutlined  style={{marginRight:5 }} onClick={() => likeVideo(id, like(index, currentUser.uid))}/> 
          
           }

             {video.likes.length > 0 && <p onClick={() => likeVideo(id, like(index, currentUser.uid))} style={{ color: currentChallenge.videos[index].likes.includes(currentUser.uid) && '#FC6D3F' }}>
              {video.likes.length} {video.likes.length > 1 ? '' : ''}</p>}
           </div>



             { currentUser?.uid === video.creatorUid && 
            <div style={{display:'flex', alignContent:'center', alignItems:'center'}}>
                <DeleteOutlined  onClick={() => deleteVideo(id, remove( video.uid))}/>
         <p onClick={() => deleteVideo(id, remove( video.uid))} style={{marginLeft: 5}}>Delete</p>
            </div>}
           
        
          </div>

          </div>}
          
          </div>
            )
          })}
         
         

          </div>            
     
  </>}

    </div>
  )
}

export default Display