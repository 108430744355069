import React, { useEffect } from 'react'
import {FireFilled} from '@ant-design/icons';
import { useAuth,  } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { RedoOutlined} from '@ant-design/icons';


function Desktop() {
    const {screenSize, pages} = useAuth()
    const navigate = useNavigate()

    useEffect(() => { 
        screenSize.width < 513 && navigate('/')
         pages('home')
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [screenSize]);

  return (
    <div className='home-container' style={{paddingRight: 20, paddingLeft: 20}}>
       <div className='login-logo' style={{justifyContent:'center', marginTop:'9vh', fontSize:30, textAlign:'center'}}>
            <FireFilled />
           <p>Yeti</p>
        </div>

       
        <div className='home-title' style={{justifyContent:'center', marginTop:130, width:'100%'}}>
        <p style={{textAlign:'center'}}>Only available on mobile.</p>
                </div>

        <p style={{textAlign:'center', marginTop:50, color:'#767676'}}><RedoOutlined /> Refresh if you are on mobile</p>
        </div>
  )
}

export default Desktop