import React, { useContext, useEffect, useState } from 'react'
import {auth, provider, db, storage} from '../Firebase'
import { signInWithPopup , signOut} from 'firebase/auth'
import {addDoc,getDocs, collection, deleteDoc, doc, updateDoc, getDoc, orderBy, query} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import {v4} from "uuid"

const AuthContext = React.createContext()


export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {

    const [currentUser, setCurrentUser] = useState()
    const [listChallenges, setListChallenges] = useState()
    const [page, setPage] = useState('')
    const [newChallengeM, setNewChallengeM] = useState('')
    const [newVideoM, setNewVideoM] = useState('')

    const [currentChallenge, setCurrentChallenge] = useState()
    const [videoUrl, setVideoUrl] = useState(null)
    const [screenSize, setScreenSize] = useState(getCurrentDimension());


    const challengesCollection = collection(db,"challenges")
    const [isLoading, setIsLoading] = useState(false)
    const [videoLoading, setVideoLoading] = useState(false)



    function getCurrentDimension(){
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
      }


     useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
    
    
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])





     function usernane (email) {
       let result = email.indexOf("@");
       let username = email.slice(0, result-2)
       return username


     } 

     function name (displayName) {
        let result = displayName.indexOf(" ");
        let name = displayName.slice(0, result)
        return name
 
      } 

    function signup() {
        setIsLoading(true)
        signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user
             
             setCurrentUser(user)
             /// console.log(result)
              setIsLoading(false)
        })
        .catch((error) => {
            /// console.log(error);
            setIsLoading(false)
        })
    }

    function signout() {
        setIsLoading(true)
        signOut(auth)
        .then((result) => {
            /// console.log(result)
        localStorage.clear()
        setCurrentUser()
        setIsLoading(false)
        })
    }




    function postChallenges(number,creator, videos, user, active, url , picture, about) {
        setIsLoading(true)
        addDoc(challengesCollection,{
            number,
           creator,
           videos,
            user,
            active,
            url,
            picture,
            about
                 } )
            .then((result) => {
            /// console.log(result)
        setIsLoading(false)
        setNewChallengeM("Nouveaux challenge ajoutée !")
        })
    }



    function getChallenge(id) {
        setIsLoading(true)
        const challenge = doc(db, "challenges", id)
         getDoc(challenge)
            .then((result) => {
                         setCurrentChallenge(result.data())

            /// console.log(currentChallenge)
        setIsLoading(false)
        })
    }




     function getChallenges() {
        setIsLoading(true)
        const first = query(collection(db,"challenges"), orderBy("number"));
        getDocs(first)
        .then((data) =>{
         setListChallenges(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})))
         setIsLoading(false)

        })

                }





    function getChallenge2(id) {
        const challenge = doc(db, "challenges", id)
         getDoc(challenge)
            .then((result) => {
                         setCurrentChallenge(result.data())

            /// console.log(currentChallenge)
        })
    }





   async function deleteChallenges(id) {
        const challenge = doc(db, "challenges", id)

    await deleteDoc(challenge )
    }



   async function participation(id, participant) {
        const challenge = doc(db, "challenges", id)
    const fields = { participants :[...participant]}

await updateDoc(challenge, fields )
}



async function likeVideo(id, video) {
    const currentVideo = doc(db, "challenges", id)
const fields = { videos :[...video]}

await updateDoc(currentVideo, fields )
}


async function deleteVideo(id, video) {
    const currentVideo = doc(db, "challenges", id)
const fields = { videos :[...video]}

await updateDoc(currentVideo, fields )
}


function newVideo(id, videoData) {
    const challenge = doc(db, "challenges", id)
const fields = { videos :[...videoData]}

 updateDoc(challenge, fields )
 .then(() => {
    setVideoUrl(null)
 })

}







        function pages(whereIAm) {
          setPage(whereIAm)
          setNewChallengeM("")

          /// console.log(page)
        }




        function addVideo(video) {
            setVideoLoading(true)
           if(video === null)  return;
           const videoRef = ref(storage, `videos/${video.name + v4()}`);
           uploadBytes(videoRef, video)
                .then((snapshot) => {
                    getDownloadURL(snapshot.ref)
                    .then((url) => {
                   setVideoUrl(url)
                   /// console.log(videoUrl)
                   setNewVideoM('Your video has been sent. If it is validated by the creator, it will appear below.')  
            setVideoLoading(false)
                    })
                         
 
            })
        }




useEffect(() => {
const unsubcribe = auth.onAuthStateChanged(user => {
    setCurrentUser(user)
})
return unsubcribe
}, [])




   const value = {
    currentUser,
    signup,
    signout,
    auth,
    usernane,
    name,
    postChallenges,
    getChallenges,
    isLoading,
    listChallenges,
    page,
    pages,
    deleteChallenges,
    newChallengeM,
    participation,
    getChallenge,
    currentChallenge,
    addVideo,
    newVideo,
    videoUrl,
    deleteVideo,
    likeVideo,
    screenSize,
    getCurrentDimension,
    newVideoM,
    videoLoading,
    getChallenge2
   }

  return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
  )
}

